.Login {
    width: 100%;
    height: 100%;
    margin: 0;
}

.Login .Title {
    padding-top: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Lato', sans-serif;
    font-size: 100px;
    color: #ccb4f3;
}

.Login .FormContainer {
    margin-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Login .FormContainer form {
    width: 40%;
}

.Login .FormContainer form .Password {
    height: 30px;
    font-size: 20px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
    border-width: 0;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 3px -1px #000;
    -moz-box-shadow: 0 0 3px -1px #000;
    box-shadow: 0 0 3px -1px #000;
    outline: none
}

.Login .SubmitButton {
    margin-top: 2%;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Login .SubmitButton .Centering {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: white;
    background-color: #ccb4f3;
    border-radius: 15px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Login .SubmitButton .Centering:hover {
    -webkit-box-shadow: 0 0 5px -2px #000;
    -moz-box-shadow: 0 0 5px -2px #000;
    box-shadow: 0 0 5px -2px #000;
}









