.Search {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: 'Lato', sans-serif;
}

.Search .Toolbar {
    width: 100%;
    height: 6%;
}

.Search .Toolbar .ToolbarContent {
    width: 100%;
    height: 6%;
    position: absolute;
    -webkit-box-shadow: 0 0 3px -1px #000;
    -moz-box-shadow: 0 0 3px -1px #000;
    box-shadow: 0 0 3px -1px #000;
    display: flex;
    flex-direction: row;
    z-index: 10;
}

.Search .Toolbar .ToolbarContent .Logo {
    margin-left: 1vw;
    height: 100%;
    width: auto;
    object-fit: contain;
}

.Search .Toolbar .ToolbarContent .Centering {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Search .Toolbar .ToolbarContent .Centering .Title {
    color: #CCB4F3;
    font-size: 5vh;
    font-family: 'Lato', sans-serif;
    margin-left: 1vw;
    margin-right: 0.5vw;
}

.Search .Toolbar .ToolbarContent .SearchForm {
    margin-left: 1vw;
    height: 50%;
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
}

.Search .Toolbar .ToolbarContent .SearchForm .SearchBar {
    width: 100%;
    height: 100%;
    border-width: 0;
    background-color: #E5E5E5;
    -webkit-box-shadow: 0 0 3px 1px #CCB4F3;
    -moz-box-shadow: 0 0 3px 1px #CCB4F3;
    box-shadow: 0 0 3px 1px #CCB4F3;
    border-radius: 5px;
    outline: none;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    font-size: 2vh;
}

.Search .Toolbar .ToolbarContent .MapSearchContainer {
    width: 30%;
    height: 3.5vh;
    margin-left: 3vw;
    margin-top: auto;
    margin-bottom: auto;
}

.Search .Toolbar .ToolbarContent .RadiusForm {
    margin-left: 2vw;
    height: 50%;
    width: 10%;
    margin-top: auto;
    margin-bottom: auto;
}

.Search .Toolbar .ToolbarContent .RadiusForm .Radius {
    width: 100%;
    height: 100%;
    border-width: 0;
    background-color: #E5E5E5;
    -webkit-box-shadow: 0 0 3px 1px #CCB4F3;
    -moz-box-shadow: 0 0 3px 1px #CCB4F3;
    box-shadow: 0 0 3px 1px #CCB4F3;
    border-radius: 5px;
    outline: none;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    font-size: 2vh;
}

.Search .Toolbar .ToolbarContent .MilesSuffix {
    margin-left: 1.25vw;
    margin-top: auto;
    margin-bottom: auto;
}

.Search .Main {
    width: 100%;
    height: 94%;
    display: flex;
    flex-direction: row;
}

.Search .Main .SearchResults {
    height: 100%;
    width: 50%;
    /*padding: 1vw;*/
}

.Search .Main .SearchResults .SRContainer {
    width: 96%;
    height: 98%;
    margin: 1% 2% 1% 2%;
    overflow-y: auto;
}

.Search .Main .SearchResults .SRContainer .StoreRow {
    width: 100%;
    margin-bottom: 1vh;
    display: flex;
    flex-direction: column;
}

.Search .Main .SearchResults .SRContainer .StoreRow .StoreName {
    width: 100%;
    height: 20px;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard {
    min-width: 250px !important;
    height: 400px;
    -webkit-box-shadow: 0 0 3px -1px #000000;
    -moz-box-shadow:  0 0 3px -1px #000000;
    box-shadow:  0 0 3px -1px #000000;
    margin: 5px;
    border-radius: 5px;
    overflow: hidden;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container {
    /*position: absolute;*/
    width: 250px;
    height: 400px;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ImageContainer {
    /*position: absolute;*/
    width: 100%;
    height: 320px;
    /*top: 0;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ImageContainer .ProductImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ImageContainer .Arrows {
    /*position: absolute;*/
    height: 320px;
    width: 250px;
    z-index: 1;
    margin-top: -320px;
    /*opacity: 50%;*/
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ImageContainer .Arrows .ArrowContainer {
    height: 60px;
    width: 100%;
    margin-top: 130px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ImageContainer .Arrows .LeftArrow {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(200, 200, 200, 0.5);
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.85);
    width: 25px;
    padding-left: 5px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    cursor: pointer;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ImageContainer .Arrows .RightArrow {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(200, 200, 200, 0.5);
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.85);
    width: 22px;
    padding-left: 8px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    cursor: pointer;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ProductInfo {
    /*position: absolute;*/
    width: 100%;
    height: 80px;
    /*bottom: 0;*/
    display: flex;
    flex-direction: row;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ProductInfo .Text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 150px;
    height: 60px;
    padding: 10px;
    font-family: 'Lato', sans-serif;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ProductInfo .Text .ProductName {
    font-size: 14px;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ProductInfo .Text .ProductPrice {
    font-size: 12px;
    font-weight: bold;
    color: #CCB4F3;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ProductInfo .StoreLogo {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Search .Main .SearchResults .SRContainer .StoreRow .Items .ProductCard .Container .ProductInfo .StoreLogo .Logo {
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin: 5px 10px 5px 0;
}

/******************/
.Search .Main .SearchResults .SRContainer2 {
    width: 96%;
    height: 98%;
    margin: 1% 2% 1% 2%;
    overflow-y: auto;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard {
    min-width: 98% !important;
    height: 256px;
    -webkit-box-shadow: 0 0 3px -1px #000000;
    -moz-box-shadow:  0 0 3px -1px #000000;
    box-shadow:  0 0 3px -1px #000000;
    margin: 5px;
    border-radius: 5px;
    overflow: hidden;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container {
    /*position: absolute;*/
    width: 100%;
    height: 256px;
    display: flex;
    flex-direction: row;

}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ImageContainer {
    /*position: absolute;*/
    width: 200px !important;
    height: 256px;
    /*top: 0;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ImageContainer .ProductImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ImageContainer .Arrows {
    /*position: absolute;*/
    height: 256px;
    width: 200px;
    z-index: 1;
    margin-top: -256px;
    /*opacity: 50%;*/
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ImageContainer .Arrows .ArrowContainer {
    height: 60px;
    width: 100%;
    margin-top: 98px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ImageContainer .Arrows .LeftArrow {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(200, 200, 200, 0.5);
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.85);
    width: 25px;
    padding-left: 5px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    cursor: pointer;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ImageContainer .Arrows .RightArrow {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(200, 200, 200, 0.5);
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.85);
    width: 22px;
    padding-left: 8px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    cursor: pointer;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ProductInfo {
    /*height: 100%;*/
    /*width: auto;*/
    margin: auto;
    display: flex;
    flex-direction: column;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ProductInfo .Text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*width: 150px;*/
    /*height: 60px;*/
    /*padding: 10px;*/
    font-family: 'Lato', sans-serif;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ProductInfo .Text .ProductName {
    height: 40px;
    font-size: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*margin-right: 50px;*/
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ProductInfo .Text .ProductPrice {
    font-size: 20px;
    font-weight: bold;
    color: #CCB4F3;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*margin-left: 50px;*/
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ProductInfo .StoreLogo {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.Search .Main .SearchResults .SRContainer2 .ProductCard .Container .ProductInfo .StoreLogo .Logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    /*margin: 5px 10px 5px 0;*/
    -webkit-box-shadow: 0 0 3px -1px #000000;
    -moz-box-shadow:  0 0 3px -1px #000000;
    box-shadow:  0 0 3px -1px #000000;
    border-radius: 5px;
}
/******************/

.Search .Main .SearchFocus {
    /*position: absolute;*/
    width: 50vw;
    height: 100%;
    z-index: 2;
    margin-left: -150%;
    background-color: white;
    -webkit-transition: margin-left .5s ease;
    -moz-transition: margin-left .5s ease;
    -o-transition: margin-left .5s ease;
}

.Search .Main .SearchFocus.InFocus {
    margin-left: -100%;
}

.Search .Main .SearchFocus .SFContainer {
    width: 96%;
    height: 98%;
    margin: 1% 2% 1% 2%;
    overflow-y: auto;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail {
    width: 100%;
    height: 100%;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .Header {
    width: 100%;
    height: 20px;
    margin-top: 2%;
    margin-bottom: 2%;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .Header .BackArrow {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain {
    width: 98%;
    height: 80%;
    margin: 0 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo {
    margin: 0.5%;
    width: 99%;
    /*height: 99%;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .Title {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductImages .ImageFocus {
    width: 25vw;
    height: 32vw;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductImages .ImageFocus .FocusedImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
    -webkit-box-shadow: 0 0 3px -1px #000;
    -moz-box-shadow: 0 0 3px -1px #000;
    box-shadow: 0 0 3px -1px #000;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo {
    width: 20vw;
    height: auto;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .PriceAndDist {
    width: 100%;
    height: 60px;
    margin-bottom: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .PriceAndDist .Price {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .PriceAndDist .Distance {
    color: #00FF55;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .PriceAndDist .Lightning {
    width: 30px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    object-fit: contain;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .Store {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    color: #666666;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .Store .StoreName {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .Store .StoreAddress {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .Reserve {
    width: 100%;
    height: 30px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .Reserve .Centering {
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    background-color: #CCB4F3;
    border-radius: 15px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .Reserve .Centering:hover {
    -webkit-box-shadow: 0 0 5px -2px #000;
    -moz-box-shadow: 0 0 5px -2px #000;
    box-shadow: 0 0 5px -2px #000;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .ReserveMessage {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-top: 6px;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .ReserveMessage.Success {
    color: #CCB4F3;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .ReserveMessage.Error {
    color: red;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .ReserveForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .ReserveForm input {
    width: 95%;
    height: 20px;
    padding-left: 2.5%;
    padding-right: 2.5%;
    font-size: 16px;
    margin-bottom: 6px;
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 5px -2px #000;
    -moz-box-shadow: 0 0 5px -2px #000;
    box-shadow: 0 0 5px -2px #000;
    outline: none;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .ReserveForm .SubmitButton, .CancelButton {
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 6px;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .ReserveForm .SBCentering, .CBCentering {
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    background-color: #CCB4F3;
    border-radius: 10px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.Search .Main .SearchFocus .SFContainer .ProductDetail .PDMain .BasicInfo .ProductInfo .ReserveForm .SBCentering:hover, .CBCentering:hover {
    -webkit-box-shadow: 0 0 5px -2px #000;
    -moz-box-shadow: 0 0 5px -2px #000;
    box-shadow: 0 0 5px -2px #000;
}






.Search .Main .ResultsMap {
    height: 100%;
    width: 50% !important;
    border-left: 1px solid #CCB4F3;
}







.Hidden {
    display: none;
}



















