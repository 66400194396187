.Lander {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: 'Lato', sans-serif;
}

.Lander .Title {
    padding-top: 20vh;
    width: 100%;
    color: #CCB4F3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
}
.Lander.Desktop .Title {
    font-size: 10vw;
}
.Lander.Mobile .Title {
    font-size: 15vw;
}

.Lander .Title .Logo {
    object-fit: contain;
}
.Lander.Desktop .Title .Logo {
    width: 10vw;
    height: 10vw;
}
.Lander.Mobile .Title .Logo {
    width: 15vw;
    height: 15vw;
}

.Lander .FormContainer {
    margin-top: 5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Lander.Desktop .FormContainer form {
    width: 33.333%;
    height: 40px;
}
.Lander.Mobile .FormContainer form {
    width: 85%;
    height: 30px;
}

.Lander .FormContainer form .Search {
    width: 100%;
    height: 100%;
    border: none;
    -webkit-box-shadow: 0 0 3px -1px #000;
    -moz-box-shadow: 0 0 3px -1px #000;
    box-shadow: 0 0 3px -1px #000;
    outline: none;
    font-family: 'Lato', sans-serif;
}
.Lander.Desktop .FormContainer form .Search {
    border-radius: 20px;
    font-size: 18px;
    padding: 0 15px 0 15px;
    margin-left: -15px;
}
.Lander.Mobile .FormContainer form .Search {
    border-radius: 15px;
    font-size: 15px;
    padding: 0 10px 0 10px;
    margin-left: -10px;
}
.Lander .FormContainer form .Search:hover {
    -webkit-box-shadow: 0 0 7px -2px #000;
    -moz-box-shadow: 0 0 7px -2px #000;
    box-shadow: 0 0 7px -2px #000;
}
.Lander .FormContainer form .Search:focus {
    -webkit-box-shadow: 0 0 7px -2px #000;
    -moz-box-shadow: 0 0 7px -2px #000;
    box-shadow: 0 0 7px -2px #000;
}

.Lander .SubmitButton {
    width: 100%;
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Lander.Desktop .SubmitButton {
    height: 40px;
}
.Lander.Mobile .SubmitButton {
    height: 30px;
}

.Lander .SubmitButton .Centering {
    height: 100%;
    background-color: #CCB4F3;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
}
.Lander.Desktop .SubmitButton .Centering {
    padding: 0 15px 0 15px;
    font-size: 18px;
    border-radius: 20px;
}
.Lander.Mobile .SubmitButton .Centering {
    padding: 0 10px 0 10px;
    font-size: 15px;
    border-radius: 15px;
}









.Hidden {
    display: none;
}



















